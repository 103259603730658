import React from 'react';
import Form from './form';
import Output from './output';
import { Container, Converter, RightSide, FileName } from './components';

export default class extends React.Component {
  state = {
    fileName: 'none',
    json: undefined,
  };

  updateJson = json => this.setState({ json });
  updateFileName = fileName => this.setState({ fileName });

  render = () => {
    return (
      <Container>
        <Converter>
          <Form
            updateFileName={this.updateFileName}
            updateJson={this.updateJson}
          />
          <RightSide>
            <FileName>Current file - {this.state.fileName}</FileName>
            <Output json={this.state.json} />
          </RightSide>
        </Converter>
      </Container>
    );
  };
}
