import React from 'react';
import { Global, css } from '@emotion/core';
import { color } from '../constants';
import 'typeface-poppins';

export default ({ children }) => (
  <>
    <Global
      styles={css`
        font-family: 'Poppins';

        body {
          background-color: ${color.background};
          margin: 0px;
        }
      `}
    />
    {children}
  </>
);
