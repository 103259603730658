import React from 'react';
import Helmet from 'react-helmet';

const schema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  inLanguage: 'en',
  name: 'Excel to JSON',
  url: 'https://exceltojson.com',
  description: 'Online Converter for Microsoft Excel to JSON',
  keywords: [
    'microsoft',
    'excel',
    'converter',
    'convert',
    'json',
    'xls',
    'xlsx',
  ],
};

const meta = [
  {
    name: `description`,
    content: schema.description,
  },
  {
    property: `og:title`,
    content: schema.name,
  },
  {
    name: `keywords`,
    content: schema.keywords.join(`, `),
  },
];

export default () => (
  <>
    <Helmet htmlAttributes={{ lang: 'en' }} title={schema.name} meta={meta} />
    {schema && (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schema, null, 4),
        }}
      />
    )}
  </>
);
