import styled from '@emotion/styled';
import { color, device } from '../../constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  font-family: 'Poppins';
  color: ${color.cyan};

  height: 200px;
`;

export const Converter = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: normal;

    max-width: 960px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;

  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: normal;
  }
`;

export const FilePicker = styled.input`
  display: none;
`;

export const FilePickerLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  background-color: ${color.purple};
  color: ${color.selection};

  font-size: 16px;
  font-family: 'Poppins';

  width: 150px;
  height: 40px;
`;

export const Submit = styled.button`
  border-radius: 5px;
  margin-left: 10px;

  font-size: 16px;
  font-family: 'Poppins';

  background-color: ${color.purple};
  border: none;
  color: ${color.selection};

  width: 150px;
  height: 40px;

  @media ${device.tablet} {
    margin-top: 10px;
    margin-left: 0px;
  }
`;

export const RightSide = styled.div`
  margin-left: 20px;
`;

export const FileName = styled.p`
  height: 20px;

  margin-top: 5px;
`;

export const Output = styled.div`
  border: solid 1px ${color.cyan};
  border-radius: 5px;

  padding: 10px;
  min-height: 300px;

  width: 85vw;
  max-width: 85vw;

  @media ${device.tablet} {
    width: 400px;
    max-width: 400px;
  }
`;
