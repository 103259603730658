import React from 'react';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Header from '../components/Header';
import Converter from '../components/Converter';

export default props => {
  return (
    <Layout>
      <Head />
      <Header />
      <Converter />
    </Layout>
  );
};
