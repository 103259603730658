import _ from 'lodash';
import React from 'react';
import XLSX from 'xlsx';
import { Submit, Form, FilePicker, FilePickerLabel } from './components';

interface IProps {
  updateFileName: any;
  updateJson: any;
}

export default class extends React.Component<IProps> {
  state = {
    file: undefined,
  };

  handleOnFileChange = async event => {
    const file = _.first(event.target.files);
    await this.props.updateFileName(file.name);
    this.setState({ file });
  };

  convertToJSON = () =>
    new Promise((resolve, reject) => {
      const { file } = this.state;
      const reader = new FileReader();
      const readAsBinaryString = !!reader.readAsBinaryString;
      reader.onload = event => {
        const bstr = event.target.result;
        const workbook = XLSX.read(bstr, {
          type: readAsBinaryString ? 'binary' : 'array',
        });
        const sheetName = _.first(workbook.SheetNames); // @TODO: Update with config
        const worksheet = _.get(workbook.Sheets, sheetName); // @TODO: Update with config
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
        resolve(json);
      };

      reader.onerror = reject;

      return readAsBinaryString
        ? reader.readAsBinaryString(file)
        : reader.readAsArrayBuffer(file);
    });

  render = () => {
    return (
      <Form
        onSubmit={async event => {
          event.preventDefault();
          const json = await this.convertToJSON();
          return this.props.updateJson(json);
        }}
      >
        <FilePickerLabel htmlFor="file-upload">Upload File</FilePickerLabel>
        <FilePicker
          type="file"
          id="file-upload"
          onChange={this.handleOnFileChange}
        />
        <Submit>Submit</Submit>
      </Form>
    );
  };
}
