import React from 'react';
import styled from '@emotion/styled';
import { color } from '../constants';

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 160px;

  font-family: 'Poppins';

  h1 {
    color: ${color.comment};

    margin-bottom: 5px;
  }

  h2 {
    font-size: 18px;
    color: ${color.comment};
  }
`;

export default () => (
  <Header>
    <h1>Excel to JSON</h1>
    <h2>Easily convert Microsoft Excel to JSON</h2>
  </Header>
);
