import React from 'react';
import Loadable from 'react-loadable';
import { Output } from './components';

const LoadableComponent = Loadable({
  loader: () => import('./jsonViewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  },
  loading: () => <div />,
});

export default output => (
  <Output>
    <LoadableComponent {...output} />
  </Output>
);
